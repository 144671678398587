import { HotelConfigActions, HotelConfigState } from './types';

import ActionTypes from './constants';
import produce from 'immer';
import initialStateHolder from 'utils/initialStateHolder';

export const initialState: HotelConfigState = initialStateHolder.setState(
  'hotelConfig',
  {
    loading: false,
    error: false,
    items: null,
    changeLogItems: null,
    changeLogLoading: null,
  },
);

const hotelConfigListReducer = (
  state: HotelConfigState = initialState,
  action: HotelConfigActions,
): HotelConfigState =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.FETCH_HOTEL_CONFIG_LIST:
        draft.isLoading = true;
        break;
      case ActionTypes.FETCH_HOTEL_CONFIG_LIST_SUCCESS:
        draft.isLoading = false;
        draft.hotelConfigList = action.payload.data!;
        break;
      case ActionTypes.FETCH_HOTEL_CONFIG_LIST_FAILURE:
        draft.isLoading = false;
        draft.error = action.payload;
        break;
      case ActionTypes.FETCH_DISTRICT:
        draft.isLoading = true;
        break;
      case ActionTypes.FETCH_DISTRICT_SUCCESS:
        draft.isLoading = false;
        draft.districtList = action.payload!;
        break;
    }
  });

export default hotelConfigListReducer;
