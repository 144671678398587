import React, { useState } from 'react';
import tw from 'tailwind.macro';
import styled from 'styled-components';
import { Button, FormInput } from '@aha/ui';
import { Alert } from 'antd';
import { Form } from 'react-final-form';
import { isEmail } from 'validator';
import { Link } from '@reach/router';

export interface LoginFormProps {
  onSubmit: (values: { email: string; password: string }) => void;
  isSubmitting?: boolean;
  forgotPasswordLink?: string;
  error?: React.ReactNode;
}

export interface LoginFormValues {
  email: string;
  password: string;
}
const Container = styled.div`
  ${tw`flex flex-col justify-between`}
  width: 294px;
`;

const ForgotPassContainer = styled.div`
  ${tw`mt-4 text-left`}
`;

const TitleForm = styled.div`
  ${tw`text-lg text-secondary leading-none font-semibold mb-8 `}
`;

export const LoginValidate = (values: LoginFormValues) => {
  const errors: Partial<LoginFormValues> = {};

  if (values.email && !isEmail(values.email)) {
    errors.email = 'Email is not valid';
  }
  if (!values.email) {
    errors.email = 'Required';
  }
  if (!values.password) {
    errors.password = 'Required';
  }

  return errors;
};
export const LoginForm = ({
  onSubmit,
  isSubmitting = false,
  forgotPasswordLink,
  error,
}: LoginFormProps) => {
  const [visiblePassword, setVisiblePassword] = useState(false);
  return (
    <Container>
      <TitleForm>Log In</TitleForm>

      <Form
        onSubmit={onSubmit}
        validate={LoginValidate}
        render={({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            id="login-form"
            data-testid="login-form"
          >
            <FormInput
              name="email"
              placeholder="Email"
              data-testid="login-form__email"
            />

            <FormInput
              name="password"
              placeholder="Password"
              data-testid="login-form__password"
              type={visiblePassword ? 'text' : 'password'}
              suffix={
                <Button
                  className="-mr-1 text-grey-darker"
                  type="icon"
                  icon={visiblePassword ? 'eye-invisible' : 'eye'}
                  data-testid="login-form__eye-btn"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setVisiblePassword(!visiblePassword);
                  }}
                />
              }
            />

            {forgotPasswordLink && (
              <ForgotPassContainer>
                <Link
                  to={forgotPasswordLink}
                  className="text-xs text-blue"
                  data-testid="login-form__pass"
                >
                  Forgot Password
                </Link>
              </ForgotPassContainer>
            )}

            <div className="mt-5">
              <Button
                color="primary"
                htmlType="submit"
                size="large"
                className="login-form-button block w-full font-medium text-base"
                disabled={isSubmitting}
              >
                Sign In
              </Button>
            </div>
            {error && (
              <div className="relative">
                <Alert
                  message={error}
                  type="error"
                  closable
                  className="mt-4 absolute w-full"
                />
              </div>
            )}
          </form>
        )}
      />
    </Container>
  );
};

export default LoginForm;
