enum ActionTypes {
  LOG_IN = 'auth/LOG_IN',
  LOG_IN_SUCCESS = 'auth/LOG_IN_SUCCESS',
  LOG_IN_FAILURE = 'auth/LOG_IN_FAILURE',
  LOG_OUT = 'auth/LOG_OUT',
  FETCH_USER = 'auth/FETCH_USER',
  FETCH_USER_SUCCESS = 'auth/FETCH_USER_SUCCESS',
}

export default ActionTypes;
