import React from 'react';
import Select, { SelectProps } from '../Select';
import { createAntField } from '../CreateAntField';
import { Field } from 'react-final-form';

// @ts-ignore
const FormSelectWrapper = createAntField<SelectProps>(Select);

export interface FormInputProps extends SelectProps<any> {
  name: string;
  label?: string;
  options: FormSelectOptions;
}

export type FormSelectOption = {
  name: string | React.ReactNode;
  value: string | number;
};
export type FormSelectOptions = FormSelectOption[];

const FormSelect = ({ options, ...props }: FormInputProps) => {
  return (
    <Field {...props} component={FormSelectWrapper} selectOptions={options} />
  );
};

export default FormSelect;
