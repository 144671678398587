import React from 'react';
import classnames from 'classnames';
import { Link } from '@reach/router';
import styled from 'styled-components';
import tw from 'tailwind.macro';

const Container = styled.div`
  ${tw`flex items-center justify-between`};
  height: 68px;
`;

const StyledLink = styled(Link)`
  ${tw`text-grey-darker`}
`;

const Separator = styled.span`
  ${tw`text-grey-darker`}
  margin: 0 5px;
`;

const Heading = styled.h1`
  ${tw`uppercase font-semibold text-secondary mb-0 text-base`}
  letter-spacing: 0.015em;
`;

export type BreadcrumbTitle = {
  href?: string;
  title?: string;
};
export interface PageHeaderProps {
  title?: BreadcrumbTitle[] | React.ReactNode;
  extra?: string | React.ReactNode;
  extraClassName?: string;
  className?: string;
  children?: any;
}

export function PageHeader({
  title,
  className = '',
  extra,
  extraClassName = '',
  children,
}: PageHeaderProps) {
  const renderedTitle =
    Array.isArray(title) && !React.isValidElement(title)
      ? (title as BreadcrumbTitle[]).map((breadcrumb, index) =>
          index < title.length - 1 ? (
            <span key={index}>
              <StyledLink to={breadcrumb.href as string}>
                {breadcrumb.title}
              </StyledLink>
              <Separator>/</Separator>
            </span>
          ) : (
            <span key={index}>
              {breadcrumb.title ? breadcrumb.title : breadcrumb}
            </span>
          ),
        )
      : title;
  return (
    <Container
      className={classnames({
        [className]: className,
      })}
    >
      <Heading>{renderedTitle}</Heading>
      {children && <div className="pl-2">{children}</div>}
      {extra && (
        <div
          className={classnames('pl-2 flex justify-end items-center', {
            [extraClassName]: extraClassName,
          })}
        >
          {extra}
        </div>
      )}
    </Container>
  );
}

export default PageHeader;
