import React from 'react';
import { ReactComponent as NoDataSVG } from '../../svg/nodata.svg';
import classnames from 'classnames';

export interface NoDataBlock {
  className?: any;
  width?: string | number;
  text?: string;
}

export const NoDataBlock: React.FC<NoDataBlock> = ({
  className,
  width = '140px',
  text = 'No Data',
}) => {
  const Component: any = NoDataSVG;
  return (
    <div
      className={classnames('flex items-center flex-col', {
        [className]: className,
      })}
    >
      <Component className="block " width={width} />
      <span className="text-base text-grey-darker pt-2">{text}</span>
    </div>
  );
};

export default NoDataBlock;
