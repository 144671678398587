import React from 'react';
import { Icon } from '@aha/ui';
import { notification } from 'antd';

export default function showSuccessNotification(
  message: string,
  description?: string,
) {
  notification.open({
    className: 'bg-green-lightest border border-green',
    message,
    description,
    icon: <Icon type="check-circle" className="text-green" />,
  });
}
