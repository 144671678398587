import * as React from 'react';
import { Tabs as AntTabs } from 'antd';
import classnames from 'classnames';
import styled from 'styled-components';
import { TabsProps, TabPaneProps } from 'antd/lib/tabs';
import tw from 'tailwind.macro';

const StyledTabs = styled(AntTabs)`
  .ant-tabs-ink-bar {
    display: none !important;
    border-radius: 0px;
  }

  .ant-tabs-bar.ant-tabs-top-bar {
    ${tw`flex flex-row flex-wrap md:block`};
  }

  .ant-tabs-nav-container {
    ${tw`w-full md:w-auto`};
  }

  .ant-tabs-nav .ant-tabs-tab {
    ${tw`inline-flex items-center leading-none h-14`};
    > span {
      ${tw`text-base font-medium leading-nọne`};
    }
  }

  .ant-tabs-extra-content {
    ${tw`pl-4 pr-6 h-14 flex items-center ml-auto w-full md:w-auto`};
  }

  .ant-tabs-tab {
    > span {
      position: relative;
      &:before {
        ${tw`absolute bg-primary`};
        content: '';
        left: 50%;
        right: 50%;
        bottom: -18px;
        height: 3px;
        transition: all 0.2s ease;
      }
    }
  }

  .ant-tabs-tab-active {
    > span {
      &:before {
        left: 0;
        right: 0;
      }
    }
  }
`;

export const Tabs: React.FC<TabsProps> & {
  TabPane: React.ClassicComponentClass<TabPaneProps>;
} = ({ children, className, ...rest }): JSX.Element => (
  <StyledTabs
    className={classnames('boxed', { [className]: className })}
    animated={false}
    tabBarGutter={16}
    tabBarStyle={{ margin: 0, paddingLeft: 8 }}
    renderTabBar={(props, DefaultTabBar) => <DefaultTabBar {...props} />}
    {...rest}
  >
    {children}
  </StyledTabs>
);

Tabs.TabPane = AntTabs.TabPane;

export default Tabs;
