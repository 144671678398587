import * as React from 'react';
import { IconType } from './type';

import { ReactComponent as UserAlt } from '../../svg/icons/user-alt.svg';
import { ReactComponent as Plus } from '../../svg/icons/plus.svg';
import { ReactComponent as ChevronLeft } from '../../svg/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../svg/icons/chevron-right.svg';
import { ReactComponent as ChevronDown } from '../../svg/icons/chevron-down.svg';
import { ReactComponent as InfoCircle } from '../../svg/icons/info-circle.svg';
import { ReactComponent as Search } from '../../svg/icons/search.svg';
import { ReactComponent as MapMarkerAlt } from '../../svg/icons/map-marker-alt.svg';
import { ReactComponent as AngleRight } from '../../svg/icons/angle-right.svg';
import { ReactComponent as Minus } from '../../svg/icons/minus.svg';
import { ReactComponent as ChartLine } from '../../svg/icons/chart-line.svg';
import { ReactComponent as CalendarCheck } from '../../svg/icons/calendar-check.svg';
import { ReactComponent as DoorOpen } from '../../svg/icons/door-open.svg';
import { ReactComponent as CalendarAlt } from '../../svg/icons/calendar-alt.svg';
import { ReactComponent as RulerVertical } from '../../svg/icons/ruler-vertical.svg';
import { ReactComponent as WarehouseAlt } from '../../svg/icons/warehouse-alt.svg';
import { ReactComponent as FileInvoice } from '../../svg/icons/file-invoice.svg';
import { ReactComponent as UserTag } from '../../svg/icons/user-tag.svg';
import { ReactComponent as Cog } from '../../svg/icons/cog.svg';
import { ReactComponent as LockAlt } from '../../svg/icons/lock-alt.svg';
import { ReactComponent as Coins } from '../../svg/icons/coins.svg';
import { ReactComponent as Hotel } from '../../svg/icons/hotel.svg';
import { ReactComponent as UserFriends } from '../../svg/icons/user-friends.svg';
import { ReactComponent as Bed } from '../../svg/icons/bed.svg';
import { ReactComponent as ListUl } from '../../svg/icons/list-ul.svg';
import { ReactComponent as Print } from '../../svg/icons/print.svg';
import { ReactComponent as CaretDown } from '../../svg/icons/caret-down.svg';
import { ReactComponent as TimesCircle } from '../../svg/icons/times-circle.svg';
import { ReactComponent as Times } from '../../svg/icons/times.svg';
import { ReactComponent as ExclamationCircle } from '../../svg/icons/exclamation-circle.svg';
import { ReactComponent as ExclamationSquare } from '../../svg/icons/exclamation-square.svg';
import { ReactComponent as Check } from '../../svg/icons/check.svg';
import { ReactComponent as Bell } from '../../svg/icons/bell.svg';
import { ReactComponent as TimesCircleSolid } from '../../svg/icons/times-circle-solid.svg';
import { ReactComponent as EllipsisV } from '../../svg/icons/ellipsis-v.svg';
import { ReactComponent as Edit } from '../../svg/icons/edit.svg';
import { ReactComponent as TrashAlt } from '../../svg/icons/trash-alt.svg';
import { ReactComponent as LongArrowAltRight } from '../../svg/icons/long-arrow-alt-right.svg';
import { ReactComponent as Globe } from '../../svg/icons/globe.svg';
import { ReactComponent as FacebookMessenger } from '../../svg/icons/facebook-messenger.svg';
import { ReactComponent as FileExport } from '../../svg/icons/file-export.svg';
import { ReactComponent as QuestionCircle } from '../../svg/icons/question-circle.svg';
import { ReactComponent as CheckCircle } from '../../svg/icons/check-circle.svg';
import { ReactComponent as Phone } from '../../svg/icons/phone.svg';
import { ReactComponent as Mail } from '../../svg/icons/mail.svg';
import { ReactComponent as UserContact } from '../../svg/icons/user-contact.svg';
import { ReactComponent as Collapse } from '../../svg/icons/collapse.svg';
import { ReactComponent as History } from '../../svg/icons/history.svg';
import { ReactComponent as Clock } from '../../svg/icons/clock.svg';
import { ReactComponent as Download } from '../../svg/icons/download.svg';
import { ReactComponent as Filter } from '../../svg/icons/filter.svg';
import { ReactComponent as MessengerSupport } from '../../svg/icons/messenger-support.svg';
import { ReactComponent as CaretRightFill } from '../../svg/icons/caret-right-fill.svg';
import { ReactComponent as LongArrowback } from '../../svg/icons/long-arrow-back.svg';
import { ReactComponent as Tag } from '../../svg/icons/tag.svg';
import { ReactComponent as SortDown } from '../../svg/icons/sort-down.svg';
import { ReactComponent as Erase } from '../../svg/icons/erase.svg';
import { ReactComponent as RemoveCircle } from '../../svg/icons/remove-circle.svg';
import { ReactComponent as PlusCircle } from '../../svg/icons/plus-circle.svg';
import { ReactComponent as Eye } from '../../svg/icons/eye.svg';
import { ReactComponent as EyeInvisible } from '../../svg/icons/eye-invisible.svg';
import { ReactComponent as ArrowRight } from '../../svg/icons/arrow-right.svg';
import { ReactComponent as Close } from '../../svg/icons/close.svg';
import { ReactComponent as BoldCheck } from '../../svg/icons/bold-check.svg';
import { ReactComponent as Hamburger } from '../../svg/icons/hambuger.svg';
import { ReactComponent as DarkQuestionCircle } from '../../svg/icons/dark-question-circle.svg';
import { ReactComponent as ArrowUp } from '../../svg/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../svg/icons/arrow-down.svg';
import { ReactComponent as Code } from '../../svg/icons/code.svg';
import { ReactComponent as Doc } from '../../svg/icons/doc.svg';
import { ReactComponent as Key } from '../../svg/icons/key.svg';
import { ReactComponent as PaperClip } from '../../svg/icons/paperclip.svg';
import { ReactComponent as Picture } from '../../svg/icons/picture.svg';
import { ReactComponent as Pdf } from '../../svg/icons/pdf.svg';
import { ReactComponent as Copy } from '../../svg/icons/copy.svg';
import { ReactComponent as Home } from '../../svg/icons/home.svg';
import { ReactComponent as ChevronUp } from '../../svg/icons/chevron-up.svg';
import { ReactComponent as CheckInBooking } from '../../svg/icons/checkin-bk.svg';
import { ReactComponent as NoShowBooking } from '../../svg/icons/noshow-bk.svg';
import { ReactComponent as CancelBooking } from '../../svg/icons/cancel-bk.svg';
import { ReactComponent as FilterSolid } from '../../svg/icons/filter-solid.svg';

const icons: {
  [k in IconType]: any;
} = {
  'user-alt': UserAlt,
  plus: Plus,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-down': ChevronDown,
  'info-circle': InfoCircle,
  search: Search,
  'map-marker-alt': MapMarkerAlt,
  'angle-right': AngleRight,
  minus: Minus,
  'chart-line': ChartLine,
  'calendar-check': CalendarCheck,
  'door-open': DoorOpen,
  'calendar-alt': CalendarAlt,
  'ruler-vertical': RulerVertical,
  'warehouse-alt': WarehouseAlt,
  'file-invoice': FileInvoice,
  'user-tag': UserTag,
  cog: Cog,
  'lock-alt': LockAlt,
  coins: Coins,
  hotel: Hotel,
  bed: Bed,
  'user-friends': UserFriends,
  'list-ul': ListUl,
  print: Print,
  'caret-down': CaretDown,
  'times-circle': TimesCircle,
  times: Times,
  'exclamation-circle': ExclamationCircle,
  'exclamation-square': ExclamationSquare,
  check: Check,
  bell: Bell,
  'times-circle-solid': TimesCircleSolid,
  'ellipsis-v': EllipsisV,
  edit: Edit,
  'trash-alt': TrashAlt,
  'long-arrow-alt-right': LongArrowAltRight,
  globe: Globe,
  'facebook-messenger': FacebookMessenger,
  'file-export': FileExport,
  'question-circle': QuestionCircle,
  'check-circle': CheckCircle,
  phone: Phone,
  mail: Mail,
  'user-contact': UserContact,
  collapse: Collapse,
  history: History,
  clock: Clock,
  download: Download,
  filter: Filter,
  'messenger-support': MessengerSupport,
  'caret-right-fill': CaretRightFill,
  'long-arrow-back': LongArrowback,
  tag: Tag,
  'sort-down': SortDown,
  erase: Erase,
  'remove-cirle': RemoveCircle,
  'plus-cirle': PlusCircle,
  eye: Eye,
  'eye-invisible': EyeInvisible,
  'arrow-right': ArrowRight,
  close: Close,
  'bold-check': BoldCheck,
  'dark-question-circle': DarkQuestionCircle,
  'arrow-up': ArrowUp,
  'arrow-down': ArrowDown,
  hamburger: Hamburger,
  doc: Doc,
  key: Key,
  code: Code,
  paperclip: PaperClip,
  picture: Picture,
  pdf: Pdf,
  copy: Copy,
  home: Home,
  'plus-circle': PlusCircle,
  'chevron-up': ChevronUp,
  'checkin-bk': CheckInBooking,
  'noshow-bk': NoShowBooking,
  'cancel-bk': CancelBooking,
  'filter-solid': FilterSolid,
};

export interface IconProps {
  type: IconType;
  className?: string;
}

export function Icon({ type, ...rest }: IconProps) {
  const Component = icons[type];

  if (!Component) {
    console.error(
      `The icon with type:${type} doesn't exist. Make sure you pass the existing one.`,
    );
    return null;
  }
  return <Component fill="currentColor" width="1em" height="1em" {...rest} />;
}

export default Icon;
