import React, { lazy } from 'react';
import { Router as ReachRouter } from '@reach/router';
import ROUTES from './constants/routes';
import ComponentRoute from 'components/ComponentRoute';
import Dashboard from 'containers/Dashboard';
import LoginPage from 'containers/Auth/LoginPage';
import PublicRoute from 'components/PublicRoute';
import CreateExtraServicePage from 'containers/CreateExtraService';
import EditExtraServicePage from 'containers/EditExtraService';
import EditInternalServicePage from 'containers/EditInternalService';
import CreateInternalServicePage from 'containers/CreateInternalService';
import HotelConfigPage from 'containers/HotelConfigPage';

const DashboardHome = lazy(() => import('containers/DashboardHome'));
const HotelsPage = lazy(() => import('containers/HotelsPage'));
const ExtraServicesPage = lazy(() => import('containers/ExtraServicesPage'));
const ExternalServicesPage = lazy(() =>
  import('containers/ExternalServicesPage'),
);
const InternalServicesPage = lazy(() =>
  import('containers/InternalServicesPage'),
);
const ExternalServicesDetailPage = lazy(() =>
  import('containers/ExternalServiceDetail'),
);
const InternalServicesDetailPage = lazy(() =>
  import('containers/InternalServiceDetail'),
);

const HotelExternalServicePage = lazy(() =>
  import('containers/HotelExternalServicesPage'),
);

const Routes = () => {
  return (
    <ReachRouter primary={false}>
      <PublicRoute path={ROUTES.LOGIN} Component={LoginPage} />
      <Dashboard path={ROUTES.HOME}>
        <ComponentRoute path={ROUTES.HOME + '*'} Component={DashboardHome} />
        <ComponentRoute path={ROUTES.HOTELS} Component={HotelsPage} />
        <ComponentRoute
          path={ROUTES.EXTRA_SERVICES}
          Component={ExtraServicesPage}
        />
        <ComponentRoute
          path={ROUTES.CREATE_EXTRA_SERVICE}
          Component={CreateExtraServicePage}
        />
        <ComponentRoute
          path={ROUTES.EDIT_EXTRA_SERVICES}
          Component={EditExtraServicePage}
        />
        <ComponentRoute
          path={ROUTES.CREATE_INTERNAL_SERVICE}
          Component={CreateInternalServicePage}
        />
        <ComponentRoute
          path={ROUTES.EDIT_EXTRA_SERVICES}
          Component={EditExtraServicePage}
        />
        <ComponentRoute
          path={ROUTES.EXTERNAL_SERVICES}
          Component={ExternalServicesPage}
        />
        <ComponentRoute
          path={ROUTES.HOTEL_CONFIG_LIST}
          Component={HotelConfigPage}
        />
        <ComponentRoute
          path={ROUTES.INTERNAL_SERVICES}
          Component={InternalServicesPage}
        />
        <ComponentRoute
          path={ROUTES.EXTERNAL_SERVICES_DETAIL}
          Component={ExternalServicesDetailPage}
        />
        <ComponentRoute
          path={ROUTES.INTERNAL_SERVICES_DETAIL}
          Component={InternalServicesDetailPage}
        />
        <ComponentRoute
          path={ROUTES.HOTEL_EXTERNAL_SERVICES}
          Component={HotelExternalServicePage}
        />
        <ComponentRoute
          path={ROUTES.EDIT_INTERNAL_SERVICE}
          Component={EditInternalServicePage}
        />
      </Dashboard>
    </ReachRouter>
  );
};

export default Routes;
