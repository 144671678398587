import React from 'react';
import Logo from '../Logo';
import tw from 'tailwind.macro';
import styled from 'styled-components';

export interface LoginLayoutProps {
  name: React.ReactNode;
  description: React.ReactNode;
  image?: string;
}

const Container = styled.div`
  ${tw`min-h-screen flex items-center justify-center bg-grey-lightest`}
`;

const Box = styled.div`
  max-width: 1000px;
  height: 595px;
`;

const LeftSideBlock = styled.div`
  ${tw`h-full bg-center bg-no-repeat bg-cover relative`};
  height: 100%;
  width: 595px;
`;

const Overlay = styled.div`
  ${tw`absolute h-full bg-cyan-darkest pin`};
  z-index: 1;
  opacity: 0.75;
`;

const Content = styled.div`
  ${tw`absolute flex flex-col mt-10 py-5 px-14`};
  z-index: 2;
`;

const Name = styled.h2`
  ${tw`text-3xl text-white font-semibold leading-tight mt-20`}
`;

const Description = styled.p`
  ${tw`text-sm text-white pt-4`};
  line-height: 1.5em;
`;

const RightSideBlock = styled.div`
  ${tw`flex flex-col justify-between flex-none`};
  width: 406px;
`;

const LoginLayout: React.FC<LoginLayoutProps> = ({
  name,
  description,
  children,
  image,
}) => {
  return (
    <Container data-testid="login-layout">
      <Box className="boxed">
        <div className="flex relative h-full">
          <LeftSideBlock
            style={{ backgroundImage: `url(${image})` }}
            data-testid="login-layout__leftside"
          >
            <Overlay />
            <Content>
              <Logo hasText scheme="light" />
              <Name data-testid="login-layout__name">{name}</Name>
              <Description data-testid="login-layout__desc">
                {description}
              </Description>
            </Content>
          </LeftSideBlock>
          <RightSideBlock data-testid="login-layout__rightside">
            {children}
          </RightSideBlock>
        </div>
      </Box>
    </Container>
  );
};

export default LoginLayout;
