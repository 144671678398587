import React from 'react';
import { Table, Button, TextBlock } from '@aha/ui';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import styled from 'styled-components';
import { formatRoute } from '@aha/utils';
import { navigate } from '@reach/router';
import ROUTES from 'constants/routes';

const LinkButton = styled.button`
  &:focus {
    outline: none;
  }
`;

export interface HotelConfigTableProps {
  data: any[] | null;
}

export default function HotelConfigTable({ data }: HotelConfigTableProps) {
  const columns: ColumnProps<any>[] = [
    {
      title: 'Hotel Name',
      key: 'name',
      render: ({ name, address, id }) => {
        if (id === null)
          return {
            children: <span>{name}</span>,
            props: {
              colSpan: 0,
              className: 'bg-grey-lightest h-8 text-grey-darker hover:bg-red',
            },
          };
        return <TextBlock title={name} subTitle={address} />;
      },
    },
    {
      title: 'internal service',
      key: 'internal',
      align: 'right',
      width: '180px',
      render: ({ internalServiceCount, id }) => {
        if (id !== null)
          return (
            <LinkButton
              className="h-16 w-40 hover:bg-green-lightest underline"
              onClick={() => {
                navigate(formatRoute(ROUTES.INTERNAL_SERVICES, id));
              }}
            >
              {internalServiceCount} Internal Service
            </LinkButton>
          );
        return {
          props: {
            colSpan: 0,
          },
        };
      },
    },
    {
      title: 'external service',
      key: 'external',
      align: 'right',
      width: '180px',
      render: ({ externalServiceCount, id }) => {
        if (id !== null)
          return (
            <LinkButton
              className="h-16 w-40 hover:bg-green-lightest underline"
              onClick={() => {
                navigate(formatRoute(ROUTES.EXTERNAL_SERVICES_HOTEL, id));
              }}
            >
              {externalServiceCount} External Service
            </LinkButton>
          );
        return {
          props: {
            colSpan: 0,
          },
        };
      },
    },
    {
      title: 'last update on pms',
      key: 'date',
      width: '200px',
      align: 'center',
      render: ({ updatedAt, id }) => {
        if (id !== null)
          return <span>{moment(updatedAt).format('DD/MM/YYYY')}</span>;
        return {
          props: {
            colSpan: 0,
          },
        };
      },
    },
    // {
    //   title: 'action',
    //   width: '140px',
    //   align: 'center',
    //   render: ({ id }) => {
    //     if (id !== null) {
    //       return (
    //         <Button
    //           data-testid="hotel-config-list-table__config-btn"
    //           size="small"
    //           className="w-24"
    //           disabled
    //           onClick={() => {
    //             console.log('Config');
    //           }}
    //         >
    //           Configure
    //         </Button>
    //       );
    //     }
    //     return {
    //       props: {
    //         colSpan: 0,
    //       },
    //     };
    //   },
    // },
  ];

  return (
    <Table
      rounded
      pagination={false}
      scroll={{ y: 800 }}
      className="bg-white"
      columns={columns}
      dataSource={data!}
    ></Table>
  );
}
