import React from 'react';
import tw from 'tailwind.macro';
import styled from 'styled-components';
import { ReactComponent as LogoIconDark } from '../../svg/logo.svg';
import { ReactComponent as LogoIconWhite } from '../../svg/logo-white.svg';
import { ReactComponent as LogoTextDark } from '../../svg/logo-text.svg';
import { ReactComponent as LogoTextWhite } from '../../svg/logo-text-white.svg';

export interface LogoProps {
  scheme?: 'dark' | 'light';
  hasText?: boolean;
}

const Container = styled.div`
  ${tw`items-center flex font-bold`};
  max-width: 100px;
  > svg:nth-child(2) {
    margin-left: 6px;
  }
`;

export const Logo = ({ hasText = false, scheme = 'dark' }: LogoProps) => {
  const LogoRender: any = scheme === 'dark' ? LogoIconDark : LogoIconWhite;
  const TextRender: any = scheme === 'dark' ? LogoTextDark : LogoTextWhite;

  return (
    <Container>
      <LogoRender width="37" height="37" className="flex-none" />{' '}
      {hasText && <TextRender />}
    </Container>
  );
};

export default Logo;
