import * as React from 'react';
import classnames from 'classnames';
import { Button as AntButton, Table as AntTable } from 'antd';
import { NoDataBlock } from '../NoDataBlock';
import getFilterColumn, { FilterColumnStyle } from './getFilterColumn';
import styled, { css } from 'styled-components';
import Pagination, {
  DEFAULT_PAGE_NUMBER,
  PAGE_SIZE_OPTIONS,
  DEFAULT_PAGE_SIZE,
} from './Pagination';
import { useMedia } from '@aha/utils';
import tw from 'tailwind.macro';
import {
  TableProps as AntTableProps,
  ExpandIconProps,
  ColumnProps,
} from 'antd/lib/table';

type DeviceSize = 'md' | 'sm' | 'lg' | 'xl';

const screens: { [k in DeviceSize]: number } = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export interface TableProps<T> extends AntTableProps<T> {
  isExpand?: boolean;
  pageSize?: number;
  isChildTable?: boolean;
  rounded?: boolean;
  noResponsive?: boolean;
  isPagination?: boolean;
  disabled?: boolean;
  hoverable?: boolean;
  borderBottom?: boolean;
  deviceSize?: 'sm' | 'md' | 'lg' | 'xl';
  onPageChange?: (page: string | number) => void;
  pagination?: PaginationProps | false;
}

export interface PaginationProps {
  totalCount: number;
  pageNumber: number;
  pageSize: number;
}

const Rounded = css`
  ${tw`border rounded-sm overflow-hidden `}
`;

const StyledTable = styled(AntTable)<TableProps<any>>`
  ${props => (props.rounded ? Rounded : '')};

  table {
    table-layout: fixed;
  }

  .ant-table-scroll table {
    width: max-content;
  }

  .ant-table table {
    border-collapse: collapse;
    margin-left: ${props => (props.isChildTable ? '-50px' : 'null')};
    width: ${props =>
      props.isChildTable ? 'calc(100% + 50px) !important;' : ''};
  }

  .ant-table-tbody > tr {
    &:last-child > td,
    .ant-table-placeholder {
      border-bottom-width: ${props =>
        (props.rounded && props.isPagination) ||
        (!props.isChildTable && props.isPagination) ||
        props.borderBottom
          ? '1px'
          : '0px'};
    }
  }

  .ant-table-content {
    > div.ant-table-fixed-left .ant-table-tbody > tr > td {
      &:last-child {
        &::after {
          content: none;
        }
      }
    }

    > div.ant-table-fixed-right .ant-table-tbody > tr > td {
      &:first-child {
        &::before {
          content: none;
        }
      }
    }
  }

  .ant-table-tbody > tr:not(.ant-table-expanded-row) {
    &:hover > td {
      background: ${props =>
        props.hoverable
          ? 'var(--grey-lightest) !important'
          : 'transparent !important'};
    }

    > td {
      ${tw`whitespace-no-wrap text-secondary pt-0 pb-0 h-14`};
    }
  }

  .ant-table-placeholder {
    border-top: 0px;
    background: ${props =>
      props.isChildTable ? 'transparent' : 'var(--white)'};
  }

  .ant-table-expanded-row {
    ${tw`border-t-2 border-grey-lightest`};
    > td {
      ${tw`border-b-0 bg-grey-lightest`};
    }
  }

  .ant-table-thead > tr > th {
    ${tw`whitespace-no-wrap text-grey-darker h-14 uppercase font-medium`};
    font-size: 11px;
    line-height: 1em;
  }

  .ant-table-expanded-row.ant-table-expanded-row-level-1 {
    > td {
      border-right-width: 0px !important;
    }
    border-top: 2px solid var(--grey-lightest) !important;
  }

  .ant-table-row-expand-icon-cell,
  .ant-table-expand-icon-th {
    padding: 8px 0px 8px 24px !important;
  }
`;

function ExpandIcon<T>(props: ExpandIconProps<T>): React.ReactNode {
  return (
    <AntButton
      icon={props.expanded ? 'caret-down' : 'caret-right'}
      shape="round"
      className="h-4 p-0 mr-0 w-4 text-secondary text-sm border-none shadow-none"
      onClick={e => props.onExpand(props.record, e)}
      ghost
    />
  );
}

const locale = { emptyText: <NoDataBlock className="p-20" /> };

export function Table<T>({
  isExpand = false,
  noResponsive = false,
  deviceSize = 'md',
  expandedRowRender,
  pageSize = 10,
  columns = [],
  onPageChange,
  pagination,
  hoverable = false,
  isChildTable,
  disabled = false,
  children,
  className = '',
  dataSource,
  scroll,
  ...rest
}: TableProps<T>): JSX.Element {
  const isMobile: boolean = useMedia(deviceSize);

  const newColumns: ColumnProps<T>[] = columns.map((col, i) =>
    col.hasOwnProperty('render')
      ? col
      : { ...col, render: text => <div className="truncate">{text}</div> },
  );

  const paginationOptions = React.useMemo(() => {
    return pagination
      ? {
          size: 'small',
          hideOnSinglePage: true,
          pageSize: pagination.pageSize || 10,
          total: pagination.totalCount,
          onChange: onPageChange,
          current: pagination.pageNumber,
        }
      : typeof pagination === 'undefined'
      ? {
          size: 'small',
          hideOnSinglePage: true,
        }
      : false;
  }, [pageSize, pagination]); // eslint-disable-line

  return (
    // @ts-ignore: style-component does not respect generic
    <StyledTable<T>
      className={classnames({
        [className]: className,
        'opacity-50 pointer-events-none': disabled,
      })}
      isExpand={isExpand}
      isChildTable={isChildTable}
      hoverable={hoverable}
      expandIcon={isExpand ? ExpandIcon : undefined}
      expandedRowRender={isExpand ? expandedRowRender : undefined}
      locale={locale}
      pagination={paginationOptions}
      columns={isChildTable ? [{ width: 50 }, ...newColumns] : newColumns}
      isPagination={(dataSource || []).length > pageSize}
      scroll={
        !isMobile || noResponsive
          ? scroll
          : { x: screens[deviceSize], ...scroll }
      }
      dataSource={dataSource}
      {...rest}
    >
      {children}
    </StyledTable>
  );
}

export { getFilterColumn, FilterColumnStyle };

Table.Pagination = Pagination;
Table.DEFAULT_PAGE_NUMBER = DEFAULT_PAGE_NUMBER;
Table.PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
Table.DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE;

export default Table;
