import { ApplicationRootState } from 'types/app';

type ValueOf<T> = T[keyof T];

const initialStateHolder = (function() {
  let instance: Partial<ApplicationRootState> | null;

  function createInstance() {
    const object = {};
    return object;
  }

  return {
    getInstance: function() {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
    setState: function(
      key: keyof ApplicationRootState,
      state: ValueOf<ApplicationRootState>,
    ) {
      const instance = this.getInstance();

      if (!instance[key]) {
        instance[key] = state;
        return instance[key];
      } else {
        console.error(`The key "${key}" was used!!!!!!!!`);
      }
    },
  };
})();

export default initialStateHolder;
