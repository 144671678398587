import React, { useState, useEffect, useMemo } from 'react';
import { SkeletonTable, PageHeader } from '@aha/ui';
import { groupBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import HotelConfigTable from './HotelConfigTable';
import { HotelHotelConfiguration } from 'types/schema';
import reducer from './reducer';
import saga from './saga';
import { fetchHotelConfigList, fetchDistrictbyCityID } from './actions';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import {
  makeSelectHotelConfig,
  makeSelectError,
  makeSelectIsLoading,
  makeSelectDistrictList,
} from './selectors';
import { ViewmodelDistrict } from './actions';
import DropdownFilter from './HotelFilterDropdown';
import useSelectDashboardState from 'containers/Dashboard/useSelectDashboardState';

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    isLoading: boolean;
    error: Error | null;
    hotelConfigList: HotelHotelConfiguration[];
    districtList: ViewmodelDistrict[];
  }
>({
  isLoading: makeSelectIsLoading(),
  error: makeSelectError(),
  hotelConfigList: makeSelectHotelConfig(),
  districtList: makeSelectDistrictList(),
});

const mapDispatchToProps = (dispatch: any) => ({
  doFetchHotelConfigList: (cityId: String, filterId: String) =>
    dispatch(fetchHotelConfigList({ cityId, filterId })),
  doFetchDistrictList: (cityId: String) =>
    dispatch(fetchDistrictbyCityID({ cityId })),
});

export default function HotelConfigPage(props: any) {
  useInjectReducer({ key: 'hotelConfig', reducer });
  useInjectSaga({ key: 'hotelConfig', saga });
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<string>('');
  const { doFetchHotelConfigList, doFetchDistrictList } = mapDispatchToProps(
    dispatch,
  );
  const { isLoading, hotelConfigList, districtList } = useSelector(
    mapStateToProps,
  );
  const { cityID } = useSelectDashboardState();

  useEffect(() => {
    doFetchHotelConfigList(cityID, '');
    if (!districtList) doFetchDistrictList(cityID);
  }, [cityID]);

  const dataFilter = useMemo(() => {
    if (filter.length === 0) return hotelConfigList;
    return hotelConfigList.filter(hotel => {
      return filter === hotel.districtID || '';
    });
  }, [hotelConfigList, filter]);

  const dataHotel = useMemo(() => {
    let dataHotelTemp = [];
    const hotelDataByDistrict = groupBy(dataFilter, c => c!.district);
    const dataDistrict = Object.keys(hotelDataByDistrict);
    const dataDetail = Object.values(hotelDataByDistrict);

    for (let i = 0; i < dataDistrict.length; i++) {
      dataHotelTemp.push({
        name: dataDistrict[i],
        id: null,
      });
      for (let j = 0; j < dataDetail[i].length; j++) {
        dataHotelTemp.push(dataDetail[i][j]);
      }
    }
    return dataHotelTemp;
  }, [dataFilter]);

  return (
    <>
      <PageHeader
        title={
          <div className="flex items-center">
            Hotels
            <span className="pl-2">
              {!isLoading && dataFilter && (
                <div className="text-grey-darker lowercase font-normal">
                  {dataFilter.length} Hotels
                </div>
              )}
            </span>
          </div>
        }
        extra={
          <div className="flex items-center">
            <DropdownFilter
              districtData={districtList}
              onFilter={values => setFilter(values)}
            />
          </div>
        }
      ></PageHeader>
      <div className="bg-white">
        {isLoading ? (
          <div className="px-4">
            <SkeletonTable />
          </div>
        ) : (
          <HotelConfigTable data={dataHotel} />
        )}
      </div>
    </>
  );
}
